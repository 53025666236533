import { Button, Layout, Menu, Space, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './Home';
import User from './User';
import Order from './Order';
import OrderDetails from './OrderDetails';
import { useRecoilState } from 'recoil';
import AuthState from '../states/AuthState';
import { RoutePath } from './RoutePath';
import { AuthStorageService } from '../services/AuthStorageService';
import AppVersion from './AppVersion';

const { Header, Content, Footer } = Layout;

const Main: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [, setAuthenticated] = useRecoilState(AuthState);

    const [selectedMenu, setSelectedMenu] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedMenu(location.pathname);
    }, [location]);

    const logout = () => { 
        AuthStorageService.remove();
        setAuthenticated(AuthStorageService.getDefaultModel());
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'fixed', width: '100%', zIndex: 1 }}>
                <Space size='large'>
                    <div className='logo'></div>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        disabledOverflow={true}
                        selectedKeys={[selectedMenu]}
                        onClick={(e) => { navigate(e.key) }}
                        items={[
                            { key: RoutePath.Home.Path, label: RoutePath.Home.Label },
                            { key: RoutePath.User.Path, label: RoutePath.User.Label },
                            { key: RoutePath.Order.Path, label: RoutePath.Order.Label },
                            { key: RoutePath.AppVersion.Path, label: RoutePath.AppVersion.Label }
                        ]}
                    />
                </Space>
                <Button type='default' onClick={() => logout()}>로그아웃</Button>
            </Header>
            <Content style={{ padding: '0 50px', position: 'relative', marginTop: 64 }}>
                <div style={{ padding: '20px 0' }}>
                </div>
                <div style={{ background: colorBgContainer, padding: '20px', minHeight: 'calc(100vh - 190px)' }}>
                    <Routes>
                        <Route path={RoutePath.Home.Path} element={<Home />} />
                        <Route path={RoutePath.User.Path} element={<User />} />
                        <Route path={RoutePath.Order.Path}>
                            <Route path={RoutePath.Order.Children.List.Path} element={<Order />} />
                            <Route path={RoutePath.Order.Children.Details.Path} element={<OrderDetails />} />
                        </Route>
                        <Route path={RoutePath.AppVersion.Path} element={<AppVersion />} />
                    </Routes>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>&copy; MarkersLab</Footer>
        </Layout>
    );
}

export default Main;