import { httpGet, httpPutEmpty } from './HttpService';

export interface IAppVersion {
    platform: string;
    version: string;
    build: number;
}

export const getAppVersion = async (platform: string) => {
    const result = await httpGet<IAppVersion>(`/app-versions/${platform}`);

    return result;
}

export const putAppVersion = async (platform: string, version: string, build: number) => {
    const result = await httpPutEmpty(`/app-versions/${platform}`, {
        version: version,
        build: build
    });

    return result;
}