import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import LoadingState from "../states/LoadingState";
import { getAppVersion, IAppVersion, putAppVersion } from "../services/AppVersionService";
import { showErrorModal } from "../components/MModal";
import { Button, Col, Descriptions, Drawer, Form, Input, Radio, Row, Spin } from "antd";

const AppVersion: React.FC = () => {
    const [loadingState, setLoadingState] = useRecoilState(LoadingState);

    const [ios, setIOS] = useState<IAppVersion>({ platform: 'ios', version: '1.0.0', build: 1 });
    const [aos, setAOS] = useState<IAppVersion>({ platform: 'aos', version: '1.0.0', build: 1 });
    const [openDrawer, setOpenDrawer] = useState(false);
    const [form] = Form.useForm();

    const loadIOS = useCallback(async () => {
        const result = await getAppVersion('ios');
        if (result.isSuccess) {
            setIOS(result.dto!);
        } else {
            showErrorModal(result.error);
        }
    }, []);

    const loadAOS = useCallback(async () => {
        const result = await getAppVersion('aos');
        if (result.isSuccess) {
            setAOS(result.dto!);
        } else {
            showErrorModal(result.error);
        }
    }, []);

    const initLoad = useCallback(async () => {
        setLoadingState(true);
        await loadIOS();
        await loadAOS();
        setLoadingState(false);
    }, [loadIOS, loadAOS, setLoadingState]);

    useEffect(() => {
        initLoad();
    }, [initLoad]);

    return (
        <Row gutter={[32, 32]}>
            <Col span={12}>
                <Descriptions title="iOS" bordered={true} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="버전명">{ios.version}</Descriptions.Item>
                    <Descriptions.Item label="빌드넘버">{ios.build}</Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={12}>
                <Descriptions title="AOS" bordered={true} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="버전명">{aos.version}</Descriptions.Item>
                    <Descriptions.Item label="빌드넘버">{aos.build}</Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24}>
                <div style={{ textAlign: "right" }}>
                    <Button type="primary" onClick={() => setOpenDrawer(true)}>버전 설정</Button>
                </div>
            </Col>
            <Drawer open={openDrawer} width={500} onClose={() => setOpenDrawer(false)} maskClosable={false} keyboard={false} title="앱 버전 설정">
                <Spin spinning={loadingState} style={{ maxHeight: "100vh" }}>
                    <Form layout="vertical"
                        form={form}
                        initialValues={{ platform: "ios", version: null, build: null }}
                        onFinish={async (values) => {
                            setLoadingState(true);
                            const result = await putAppVersion(values.platform, values.version, values.build);
                            if (result.isSuccess) {
                                await loadIOS();
                                await loadAOS();
                            } else {
                                showErrorModal(result.error);
                            }
                            form.resetFields();
                            setLoadingState(false);
                            setOpenDrawer(false);
                        }}>
                        <Form.Item label="플랫폼" name={"platform"}>
                            <Radio.Group>
                                <Radio.Button value="ios">iOS</Radio.Button>
                                <Radio.Button value="aos">AOS</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="버전명" rules={[{ required: true, max: 20 }]} required={true} name="version">
                            <Input placeholder="버전명를 입력하세요. 예) 1.0.0" />
                        </Form.Item>
                        <Form.Item label="빌드넘버" rules={[{ required: true }]} required={true} name="build">
                            <Input placeholder="빌드넘버를 입력하세요. 예) 123" />
                        </Form.Item>
                        <Form.Item style={{ textAlign: "center" }}>
                            <Button type="primary" htmlType="submit">확인</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Drawer>
        </Row>
    );
}

export default AppVersion;